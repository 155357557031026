<!--
  文件名：datainsight
  描述：数据洞察
  修改人：高琛
  修改时间：2020-01-19
  修改内容：新增页面
  -->
<template>
  <div>
    <div class="" >
      <div class="bannerBg" >


      </div>
      <div class="dataserve">
        <div class="datainsight  ">
          <div class="container">
            <div class="title" style="text-align: center;">
              <span class="title_info" style="font-size: 44px;">数据驱动的产品服务</span>
            </div>
            <!-- <div class="info">
            <p>
              中思拓研究院较早建设数据库，历经十余载持续积累，建成庞大的行业全链条数据集、专精的住房标准地址库数据和领先的“房屋智能估价系统”，融合数据生产、治理、分析、建模等全方位服务，为金融机构、企业、有关部门及科研院所等提供精准、高效的大数据解决方案。
            </p>
          </div> -->
            <div class="row row-2">
              <div class="title">
                <span class="icon">1</span>
                <span class="title_info">数据产品与特色服务</span>
              </div>
              <div class="info">
                <p>
                <div class="text-black title-font">
                  1. 一手房屋数据
                </div>
                <span class="text-black"> 精准监测：</span>
                全国275个城市一手房屋数据精准监测、统计和高频更新。
                <div>
                  <span class="text-black"> 全面监测： </span>
                  从成交分时统计，到项目基础信息、销售许可、监管账号及周边情景等多维度数据，助力用户精准把握一线市场动态。
                </div>
                <div>
                  <span class="text-black"> 预警功能： </span>
                  提供住房价格走势分析，对异常波动预警，提前规避风险。
                </div>
                </p>
                <p style="margin-top: 30px;">
                <div class="text-black title-font">
                  2.全国房屋地址数据治理
                </div>
                <span class="text-black">广泛覆盖：</span>遍及全国31个省级区划（除港澳台），覆盖全国90%城市，最大化满足用户需求的广泛性。
                <div>
                  <span class="text-black"> 精细治理： </span>
                  通过9级地址体系与10余项楼栋、房屋属性数据的综合治理，为数据应用奠定坚实基础。
                </div>
                </p>
                <p style="margin-top: 30px;">
                <div class="text-black title-font">
                  3.市场监测与预警报告
                </div>
                <span class="text-black">深度分析：</span>
                依托房屋智能估价系统与全链条数据集，构建房价指数体系，动态监测市场变化，精准预警潜在风险。每月10日定期出刊报告，全年共计12期。
                <div>
                  <span class="text-black"> 样本精选： </span>
                  动态监测广东省内3.8万余楼盘中的300-500个代表楼盘，持续优化监测范围，确保预警的精准性与普遍性。
                </div>
                </p>
              </div>

              <p style="margin-top: 30px;">
              <div class="text-black title-font">
                4.数据定制
              </div>
              <span class="text-black">定制：</span>
              基于六大核心数据库，提供数据定制，满足用户多元化、深层次的数据需求。

              </p>

            </div>

          </div>
          <div class="row row-1">
            <div class="title">
              <span class="icon">2</span>
              <span class="title_info">数据生产与服务亮点</span>
            </div>
            <div class="info">
              <p>
              <div class="text-black title-font">
                1. 中国房地产金融全链条数据集
              </div>
              <span class="text-black"> 数据权益：</span>
              全国首批成功数据确权，获《数据资源持有权证书》、《数据加工使用权证书》及《数据产品经营权证书》，确保数据的安全、合法。
              <div>
                <span class="text-black"> 服务范围： </span>
                涵盖房屋交易、房屋属性、房屋地址、价格模型、房企监测、金融市场、土地市场及宏观经济等多维度数据，为决策提供全视角的市场洞察。
              </div>
              </p>
              <p style="margin-top: 30px;">
              <div class="text-black title-font">
                2.住房标准地址库与住房市场金融风险监测预警
              </div>
              <span class="text-black">治理目标：</span>
              针对住房地址不规范的问题，实施全面标准化治理，通过智能匹配与人工复核，实现房屋地址精准定位至楼盘、楼栋及房屋单元，提升数据应用价值。
              <div>
                <span class="text-black"> 治理深度： </span>构建9级标准地址体系，详尽收录楼栋及房屋属性信息，确保数据完整性与准确性。
              </div>
              </p>
              <p style="margin-top: 30px;">
              <div class="text-black title-font">
                3. 房屋智能估价系统
              </div>
              <span class="text-black">创新应用：</span>
              利用算法，对房屋进行实时、公允、正确估价，为金融机构等多类型用户提供预警和风险管理的决策依据。
              </p>
            </div>

          </div>
          <div class="info">
            <p>
              中思拓研究院以数据为基础、以研究为手段、以“数据+科技”为核心竞争力，致力打造智能、高效的数据生态系统。以数据要素驱动，以先进技术引领，以集体赋智创新，诚挚期待与用户共同携手，激活数据要素价值，探索挖掘数据价值。
            </p>
          </div>
          <div class="row row-3">
            <div class="title">
              <span class="icon">3</span>
              <span class="title_info">数据可视化</span>
            </div>
            <div class="info">
              <p>
                基于五大数据板块，平台开发了一系列可视化报表，可供用户查看；同时可以根据用户需求去定制报表，可以是基于平台的数据，也可以结合其他非平台的数据，进行报表开发。

              </p>
            </div>
            <!-- <div class="secTitle">宏观经济可视化示例:</div>
                  <div class="img">
                      <img src="../../assets/img/ds-9.gif" alt="">
                  </div> -->
            <div class="secTitle threeTitle">
              城市系统可视化实例
            </div>
            <div class="img">
              <!-- <img src="../../assets/img/ds-16.gif" alt=""> -->
              <img src="../assets/image/img/ds-16.gif" alt="">
            </div>
            <div class="secTitle threeTitle">
              企业监测可视化实例
            </div>
            <div class="img bottomImg">
              <img src="../assets/image/img/ds-17.gif" alt="">
            </div>
            <div class="secTitle threeTitle">
              土地市场可视化实例
            </div>
            <div class="img">
              <img src="../assets/image/img/ds-15.gif" alt="">
            </div>

            <div class="secTitle threeTitle">
              宏观经济可视化实例
            </div>
            <div class="img">
              <img src="../assets/image/img/ds-14.gif" alt="">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
export default {
  name: 'DataInsight',
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/dataserve.scss';
@import "@/style/common.scss";

.content {
  overflow: auto;

}

.page {
  // overflow: hidden;
  min-height: 100%;
  min-width: 1200px;
  // padding: 20px;
  margin: auto;
  width: 100%;
  overflow: auto;
  flex-direction: column;

}


.bannerBg {
  width: 1920px;
  height: 430px;
  margin: auto;
  //background-image: url("../../assets/img/homeBanner3.jpg");
  background-image: url("../assets/image/homeBanner3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bottomImg {
  margin-bottom: 60px;
}

.row-3 .img {
  margin-top: 15px;
}

.text-black {
  //字体加重
  font-weight: bold;

}

.title-font {
  font-size: 20px;
}

.info {
  font-size: 16px;
  line-height: 30px;
}

@mixin middle {
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

@mixin default-text {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}

@mixin inline-block {
  display: inline-block;
  vertical-align: top;
}

@mixin background {
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataserve {
  // min-height: 1280px;
  padding: 0 400px;
  margin-top: 74px;
  // overflow: auto;

  .sideNav {
    position: absolute;
    ;
    top: 540px;
    left: 50%;
    width: 250px;
    height: 600px;
    transform: translateX(-50%);
    margin-left: -490px;
    z-index: 99;

    &.fixed {
      position: fixed;
      top: 160px;
    }

    &.absolute {
      position: absolute;
      top: inherit;
      bottom: 420px;
    }

    .main {
      border-top: 1px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      padding-top: 10px;
      padding-bottom: 20px;

      .oneSide {
        @include default-text;
        font-size: 24px;
        line-height: 72px;
        font-weight: bold;
        color: #CBCBCB;

        .name {
          cursor: pointer;
        }

        .secSideBox {
          .secSide {
            @include default-text;
            font-size: 20px;
            line-height: 40px;
            color: #CBCBCB;
            cursor: pointer;
            margin-top: 10px;

            &.active {
              color: #263E8C;

              .navIndex {
                background: #263E8C;
                color: white;
              }
            }

            .navIndex {
              display: inline-block;
              width: 30px;
              height: 30px;
              color: #CBCBCB;
              border: 1px solid #CBCBCB;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
              margin-right: 8px;
            }
          }

        }

        &.active {
          color: #263E8C;
        }
      }
    }

    .ad {
      @include background;
      margin-top: 50px;
      width: 250px;
      height: 124px;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
    }

    .toPlatform {
      @include default-text;
      cursor: pointer;
      margin-top: 20px;
      width: 250px;
      height: 58px;
      background: #FFFFFF;
      border: 1px solid #263E8C;
      outline: none;
      font-size: 18px;
      color: #263E8C;
      cursor: pointer;

    }


  }

  .content {
    width: 940px;
    overflow: auto;

    .row {
      margin-top: 50px;
    }
  }

  //子组件样式修正
  .datainsight {
    .row {
      width: 100%;
    }
  }

  .collateral {
    .row {
      width: 100%;
    }
  }

  .viewitems {
    .row {
      width: 100%;
    }
  }

  .customization {
    .row {
      width: 100%;
    }

    .img {
      margin-top: 60px;
    }

    .row-3 {
      margin-top: 100px;
    }
  }

  .exchange {
    .row {
      width: 100%;
    }
  }

  .title {
    margin-top: 48px;
    @include default-text;
    font-size: 36px;
    line-height: 54px;
    font-weight: bold;
    color: #263E8C;

    .icon {
      display: inline-block;
      width: 60px;
      height: 60px;
      background: #263E8C;
      opacity: 0.2;
      border-radius: 50%;
      font-size: 40px;
      font-family: Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 60px;
    }

    .title_info {
      margin-left: 20px;
    }

  }

  .info {
    padding: 30px 20px 42px;
    background: #FAFAFA;
    margin-top: 30px;

    .secTitle {
      @include default-text;
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #263E8C;
      padding-bottom: 30px;
      margin-bottom: 30px;
      box-sizing: border-box;
      border-bottom: 1px solid #EAEAEA;

    }

    p {
      @include default-text;
      font-size: 16px;
      line-height: 32px;
      color: #656565;
      text-indent: 2em;
    }

    pre {
      @include default-text;
      font-size: 16px;
      line-height: 32px;
      color: #656565;
    }
  }

  .secTitle {
    @include default-text;
    font-size: 20px;
    line-height: 36px;
    font-weight: bold;
    color: #263E8C;
    // padding-bottom: 30px;
    margin-top: 30px;
    box-sizing: border-box;
    // border-bottom: 1px solid #EAEAEA;

  }

  .threeTitle {
    text-align: center;
  }

  .img {
    margin-top: 30px;
    text-align: center;
    width: 100%;

    img {
      max-width: 100%;
    }
  }


}
</style>